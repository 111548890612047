@import 'bootstrap.scss';
@import 'color.scss';

@import 'themify.scss';
@import 'whether-icon/whether-icon';


@import "flag-icon/flag-icon-base";
@import "flag-icon/flag-icon-more";
@import "flag-icon/flag-icon-list";

@import 'icoicon/icons';
@import 'font-awesome.scss';

@import 'chartist/chartist-settings';
@import 'chartist/chartist';

.ant-tabs-tab{
    margin: 0 32px 0 0 !important;
}

