/**=====================
   61. base_inputs CSS start
==========================**/
.base_inputs {
  .form-group {
    margin-bottom: $form-group-margin-bottom;
    .col-form-label {
      font-size: $col-form-label-font-size;
    }
    .form-control {
      font-size: $form-control-font-size;
      border-radius: $form-control-border-radious;
      border: 1px solid $form-control-border-color;
    }
    input:focus,
    textarea:focus,
    select:focus {
      outline: none;
      box-shadow: none !important;
      border: 1px solid $form-control-border-color !important;
    }
  }
}
/**=====================
    61. base_inputs CSS ends
==========================**/










