.form-group input:focus {
    outline: none;
}
.formik-field_wrap {
    display: inline-block !important ;
    width: 100% !important;
    margin-top: 10px !important;
}
.formik-field-left {
    width : 40% !important;
    float: left !important;
}
.formik-field-right {
    width : 40% !important;
    float: right !important;
}
.formik-field_wrap input {
    border-radius: 5px;
}
.sidebar-header .anticon svg {
    margin-bottom: 4px;
}
.setting-form .form-field {
    width: 25%;
    margin-bottom: 15px;
}
.setting-form .form-field input {
    border-radius: 10px;
}
.setting-form .error-message {
    color: red;
    margin-top: 5px;
}
.DraftEditor-root {
    height: 300px;
    background: white;
    margin-bottom: 30px;
}

.ck-content {
    height: 300px;
    background: white;
} 

.ck-editor__editable {
    height: 300px;
    background: white;
}
.input-label {
    margin-bottom: 5px;
    font-weight: bold;
    margin-left: 3px;
}
.ant-upload-select-picture-card i {
    color: #999;
    font-size: 32px;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
.question_form_wrap .formik-field-left {
    width : 28% !important;
    float: left !important;
    margin-left: 20px;
}
.question_form_wrap .formik-field-left:first-child {
    width : 28% !important;
    float: left !important;
    margin-left: 0px;
}
.question_form_wrap .formik-field-left:last-child {
    width : 8% !important;
    float: left !important;
    margin-left: 20px;
}
.sub-admin_card_buy_report{
    display: flex;
    gap: 10px;
}
/*========== Sidebar Accordion CSS  =============*/
.active .sidebar_accordion_title  i{
  transform: rotate(90deg);
  transition: all .3s ease;
}
.sidebar_accordion_title  i{
    margin-top: 3px;
  }
.sidebar_accordion_title{
    color: #313131;
    font-size: 14px;
    letter-spacing: .5px;
    padding-bottom: 8px;
    padding-top: 8px;
    text-transform: capitalize;
    font-weight: 600;
    font-family: Nunito;
    transition: all .3s ease;
    display: block;
    cursor: pointer;
    animation: sidebar-menu-open .3s alternate forwards;
}
.sidebar_accordion_title:hover{
    color: #3949ab;
    padding-left: 3px;
    transition: .3s;
}
.sidebar_accordion_title svg {
    width: 14px;
    height: 14px;
    margin-right: 14px;
    stroke-width: 3px;
    vertical-align: sub;
}